<template>
  <div>
    <b-row v-if="orders.length">
      <b-col
        sm="12"
        md="6"
        lg="8"
      >
        <b-card no-body>
          <deliveries-timeline :orders="orders" />
        </b-card>
      </b-col>
    </b-row>
    <b-row
      v-if="orders.length === 0"
    >
      <b-col class="mt-3 text-center">
        <h1 class="display-3">
          🙃
        </h1>
        <p class="h1">
          No se te han asignado entregas
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DeliveriesTimeline from '@/@core/components/DeliveriesTimeline.vue'
import { getUserData } from '@/auth/utils'
import { mapActions } from 'vuex'
import {
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    DeliveriesTimeline,
    BRow,
    BCol,
    BCard,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      orders: [],
    }
  },
  beforeMount() {
    const currentUser = getUserData()
    this.fetchOrders({
      by_dispatcher: currentUser.id,
      by_is_refund: false,
    }).then(response => {
      this.orders = response.data
      this.ordersPagination = response.meta.pagination
    })
  },
  mounted() {
    this.deliveriesConnection = new WebSocket(
      `${process.env.VUE_APP_WSS_URL}/cable?token=${this.userData.token}`,
    )
    sessionStorage.setItem('wsConnectionDeliveries', JSON.stringify(this.deliveriesConnection))

    this.deliveriesConnection.onmessage = event => {
      const currentUser = getUserData()
      const messagex = JSON.parse(event.data)
      if (typeof messagex.message === 'object') {
        this.$swal({
          title: messagex.message.title,
          text: messagex.message.content_message,
          icon: 'warning',
          showCancelButton: false,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        this.fetchOrders({
          by_dispatcher: currentUser.id,
          by_is_refund: false,
        }).then(response => {
          this.orders = response.data
          this.ordersPagination = response.meta.pagination
        })
      }
    }

    this.deliveriesConnection.onopen = () => {
      // eslint-disable-next-line
      console.log('Successfully connected to the echo websocket server...')

      // eslint-disable-next-line
      this.deliveriesConnection.send(JSON.stringify({ "command": "subscribe","identifier":"{\"channel\":\"ApplicationCable::MyChannel\"}" }))
    }
  },
  destroyed() {
    this.deliveriesConnection.close()
  },
  methods: {
    ...mapActions('orders', ['fetchOrders']),
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.lock-icon {
  font-size: 25px;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
